@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        @apply leading-normal;
    }

    /* Headings */
    h1 {
        @apply my-3 text-4xl font-bold;
    }

    h2 {
        @apply my-4 text-3xl font-semibold;
    }

    h3 {
        @apply my-5 text-2xl font-semibold;
    }

    h4 {
        @apply my-6 text-xl font-semibold;
    }

    h5 {
        @apply my-8 text-lg font-medium;
    }

    h6 {
        @apply my-10 text-base font-medium;
    }

    /* Paragraphs */
    p {
        @apply my-2;
        @apply text-base font-normal;
    }

    /* Blockquotes */
    blockquote {
        @apply my-4 px-8 border-l-4 border-gray-200;
    }

    /* Horizontal Rules */
    hr {
        @apply my-6 border-t;
    }

    /* Lists */
    dl,
    ol,
    ul {
        @apply text-base font-normal;
        @apply my-4 pl-3 list-inside;
    }

    /* Definition Lists */
    dl {
        dt {
            /* @apply font-semibold; */
        }

        dd {
            /* @apply ml-6; */
        }
    }

    /* Ordered Lists */
    ol {
        /* @apply list-decimal; */
    }

    /* Unordered Lists */
    ul {
        @apply list-disc;
    }

    /* Anchors */
    /* a {
        @apply underline;
    } */

    /* Tables */
    table {
        @apply table border-separate border-gray-200 align-middle text-left bg-gray-200 rounded;

        caption {
            @apply p-3;
        }

        thead tr th {
            @apply py-2 px-4;
        }

        tbody tr td {
            @apply py-2 px-4;
        }

        tfoot tr th {
            @apply py-2 px-4;
        }
    }

    /* Forms */
    input,
    textarea {
        @apply block px-3 py-2 border text-sm; /* rounded */
    }

    input:focus,
    textarea:focus {
        @apply outline-none;
    }

    label {
        @apply block mb-2 text-sm font-semibold;
    }

    input[type="submit"],
    input[type="button"],
    input[type="reset"] {
        @apply inline-block px-4 py-2 bg-gray-200 font-semibold;
    }

    /* Images */
    figure {
        @apply inline-block;

        img {
            @apply block w-full;
        }

        figcaption {
            @apply block p-3 text-center text-sm;
        }
    }
}

@layer components {
    .form-input {
        @apply w-full bg-gray-100 bg-opacity-50 border  focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out border-gray-300 focus:border-indigo-500;
        /* rounded-md */
    }

    .form-text-area {
        @apply w-full bg-gray-100 bg-opacity-50 border focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out border-gray-300 focus:border-indigo-500;
        /* rounded-md */
    }

    .primary-button {
        @apply flex border-0 focus:outline-none text-lg bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-800 font-semibold py-2 px-4 text-white;
        /* rounded-lg */
    }

    .default-image {
        @apply m-auto max-w-full h-auto align-middle;
        /* rounded-lg */
    }

    .light-theme {
        @apply bg-gradient-to-b from-gray-50 to-white text-gray-700;
    }
}

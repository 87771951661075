body {
    margin: 0;
    font-family: "Exo 2", "Ubuntu", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
    height: 100%;
    /* background-color: aliceblue; */
}
